

















import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class BoxSidebarContainer extends Vue {
  @Prop() title!: string;
  @Prop() fluid!: boolean;

  private appClass = 'app-box';

  created() {
    if (this.fluid !== undefined) {
      this.appClass = 'app-box-fluid';
    }
  }
}
