var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"filter"},[_c('div',{staticClass:"filter__header"},[_c('h4',[_vm._v("Filtros")]),_c('button',{on:{"click":_vm.closeFilter}},[_c('Close')],1)]),_vm._l((_vm.DATA_ACCORDION),function(item,index){return _c('div',{key:index,attrs:{"id":("accordion-" + (item.id))}},[(_vm.hasContent(item))?_c('Accordion',{attrs:{"parentID":item.id,"id":("" + (item.id) + (item.id)),"name":item.name,"show":false,"showArrow":true,"idItem":item.idName,"padding":"10px 0"},on:{"change-symbol":_vm.setShowTextFilter},scopedSlots:_vm._u([{key:"checkbox",fn:function(){return [_c('div',{staticClass:"filter__wrapper-checkbox"},[_c('Checkbox',{attrs:{"setCheckbox":_vm.setCheckbox,"checked":item.checked,"index":item.id,"name":item.name}})],1)]},proxy:true},(item.id !== _vm.ID_TEACHERS)?{key:"header-aditional",fn:function(){return [_c('p',{staticClass:"filter__accordion-data"},[_vm._v(" "+_vm._s(_vm.dataFilters[item.idName].total.totalCount)+" "+_vm._s(_vm.formatTime(_vm.dataFilters[item.idName].total.totalTime))+" ")])]},proxy:true}:null],null,true)},[(item.id !== _vm.ID_TEACHERS)?_c('FilterByContext',{attrs:{"infoCategoryProp":_vm.dataFilters[item.idName].categories,"context":item.idName},on:{"set-checkbox":_vm.updateCheckedListData}}):(_vm.listTeachers.length)?_c('Teachers',{attrs:{"listTeachers":_vm.listTeachers,"showRating":false}}):_vm._e()],1):_vm._e(),(item.id === _vm.ID_VIDEOS
        && _vm.showTextFilter.videos
        && _vm.hasContent(item))?_c('div',{staticClass:"filter__filtered-text",domProps:{"innerHTML":_vm._s(_vm.getTextFilterVideos)}}):(item.id === _vm.ID_EXERCISES
        && _vm.showTextFilter.exercices
        && _vm.hasContent(item))?_c('div',{staticClass:"filter__filtered-text",domProps:{"innerHTML":_vm._s(_vm.getTextFilterExercises)}}):(item.id === _vm.ID_MATERIALS
        && _vm.showTextFilter.archives
        && _vm.hasContent(item))?_c('div',{staticClass:"filter__filtered-text",domProps:{"innerHTML":_vm._s(_vm.getTextFilterMaterials)}}):(item.id === _vm.ID_TEACHERS
        && _vm.showTextFilter.teachers
        && _vm.hasContent(item))?_c('div',{staticClass:"filter__filtered-text",domProps:{"innerHTML":_vm._s(_vm.getTextFilterTeachers)}}):_vm._e()],1)}),_c('p',{staticClass:"filter__total"},[_vm._v(" Total "+_vm._s(_vm.getTotalData.totalCount)+" "+_vm._s(_vm.formatTime(_vm.getTotalData.totalTime))+" ")]),_c('button',{staticClass:"ex-button ex-button-primary-inverse filter__button-clean",on:{"click":_vm.resetCheckboxDataAccordion}},[_vm._v(" Limpar seleção ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }