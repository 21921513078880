

















































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';

import SubjectBox from '@/components/Subject/SubjectBox/index.vue';
import SubjectInfo from '@/components/Subject/SubjectInfo/index.vue';
import AdditionalContentData from '@/components/AdditionalContentData/index.vue';

import SubjectAccordion from '../SubjectAccordion/index.vue';

import { TopicListBook } from '@/globalInterfaces/Book';

@Component({
  components: {
    Loading,
    SubjectBox,
    SubjectInfo,
    SubjectAccordion,
    AdditionalContentData,
  },
})
export default class BookContent extends Vue {
  @Prop() bookTopic!: Array<TopicListBook>;
  @Prop() idBook!: number;

  get bookSlug() {
    return this.$route.params.path;
  }

  showSubjectBox(subject: TopicListBook) {
    return subject.topics.length || Object.values(subject.videos) || Object.values(subject.archives) || Object.values(subject.exercices);
  }
}
