const CLASS_OPTIONS_BY_DEGREE = [
  {
    degreeID: 4,
    title: 'Aulas do Ensino Fundamental 1',
    value: 1,
  },
  {
    degreeID: 1,
    title: 'Aulas do Ensino Fundamental 2',
    value: 2,
  },
  {
    degreeID: 2,
    title: 'Aulas do Ensino Médio',
    value: 3,
  },
];

export {
  CLASS_OPTIONS_BY_DEGREE,
};
