


































































import {
  Component,
  Prop,
  Mixins,
} from 'vue-property-decorator';

import store from '@/store';

import ContainerSidebar from '@/components/ContainerSidebar/ContainerSidebar.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import Book from '@/components/Book/index.vue';
import PlanUpdateSidebar from '@/components/PlanUpdateSidebar/index.vue';
import BookSidebarLoading from '@/components/BookSidebarLoading/index.vue';
import SelectBook from '@/components/Input/Select/Select.vue';

import ContentDetails from './components/ContentDetails/index.vue';
import ContentFilter from './components/ContentFilter/index.vue';

import Teachers from '@/widgets/Teachers/index.vue';
import RealtimeUsers from '@/widgets/Users/RealtimeUsers/RealtimeUsers.vue';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { DEFAULT_USERS } from '@/constant/AvatarsExp';
import { CLASS_OPTIONS_BY_DEGREE } from './constants';
import { PERMISSION_NAME_BY_DEGREE_ID } from '@/constant/PermissionBookByDegreeID';

import { Degree, TopicListBook } from '@/globalInterfaces/Book';
import {
  Teacher,
  TeacherFilter,
} from '@/globalInterfaces/Teachers';
import { RelatedDisciplines, OptionDegreeSelect } from './interface';

import Permission from '@/mixins/permission';

import BookService from '@/services/Book/BookService';

@Component({
  components: {
    BookSidebarLoading,
    ContainerSidebar,
    ContainerFluid,
    Book,
    Teachers,
    PlanUpdateSidebar,
    SelectBook,
    ContentDetails,
    ContentFilter,
    RealtimeUsers,
  },
})
export default class BookSidebar extends Mixins(Permission) {
  @Prop() degreeID!: number;
  @Prop() idBook!: number;
  @Prop() cover!: string;
  @Prop() colorBook!: string;
  @Prop() title!: string;
  @Prop() slugBook!: string;
  @Prop() router!: any;
  @Prop() bookTopic!: Array<TopicListBook>;
  @Prop() teachers!: Array<Teacher>;

  private showFilter = false;
  private topicsQuantity: Record<string, any> = {
    videos: {},
    exercices: {},
    archives: {},
    questions: {},
  }
  private listTeachers: Array<TeacherFilter> = [];
  private listSelectBookDegree: OptionDegreeSelect[] = [];
  private relatedDisciplines: RelatedDisciplines[] = [];
  private degreesWithPermission: Degree[] = [];

  private BookService = new BookService();

  created() {
    this.setSelectBookData();
    this.findTeachers(this.teachers);
    this.quantityTopic();
  }

  get hasPermissionInBooksAnotherDegree() {
    if (!this.degreesWithPermission.length) return false;

    return this.degreesWithPermission.some((degree: Degree) => (degree.ID !== this.degreeID));
  }

  get optionSelectedCurrentBook() {
    return this.listSelectBookDegree.length ? this.listSelectBookDegree.find((option: OptionDegreeSelect) => (option.degreeID === this.degreeID))?.value : null;
  }

  get isPlanFree() {
    return store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get showLoading() {
    return this.colorBook || this.cover || this.title;
  }

  async setSelectBookData() {
    await this.getDegrees();
    this.getRelationshipDiscipline();
  }

  async getDegrees() {
    try {
      const degrees = await this.BookService.getDegrees();

      this.getDegreeIDsWithPermission(degrees);
    } catch (error) {
      console.error(error);
    }
  }

  getDegreeIDsWithPermission(degrees: Degree[]) {
    this.degreesWithPermission = degrees.filter((degree: Degree) => (this.can(PERMISSION_NAME_BY_DEGREE_ID[degree.ID])));
  }

  async getRelationshipDiscipline() {
    if (!this.hasPermissionInBooksAnotherDegree || this.idBook === undefined) return;

    try {
      const relatedDisciplines = await this.BookService.getRelationshipDiscipline([this.idBook]);

      this.relatedDisciplines = this.filterRelationshipDisciplineWithPermission(relatedDisciplines);

      const listSelectBookDegree = this.createSelectWithRelationshipDisciplines(this.relatedDisciplines);

      this.listSelectBookDegree = listSelectBookDegree;
    } catch (error) {
      console.error(error);
    }
  }

  filterRelationshipDisciplineWithPermission(relatedDisciplines: RelatedDisciplines[]) {
    if (!relatedDisciplines.length) return [];

    const relatedDisciplinesWithPermission = relatedDisciplines.filter((discipline: RelatedDisciplines) => (this.can(PERMISSION_NAME_BY_DEGREE_ID[discipline.degreeID])));

    return relatedDisciplinesWithPermission;
  }

  createSelectWithRelationshipDisciplines(relatedDisciplines: RelatedDisciplines[]) {
    if (!relatedDisciplines.length) return [];

    const listSelectBookDegree = CLASS_OPTIONS_BY_DEGREE.filter((option: OptionDegreeSelect) => relatedDisciplines.find((discipline: RelatedDisciplines) => option.degreeID === discipline.degreeID || option.degreeID === this.degreeID));

    return listSelectBookDegree;
  }

  quantityTopic() {
    if (this.bookTopic && this.bookTopic.length) {
      this.bookTopic.forEach((topic: TopicListBook) => {
        this.setQuantityTopics(topic);
      });
    }
  }

  setQuantityTopics(topic: Record<string, any>) {
    if (topic.topics && topic.topics.length) {
      topic.topics.forEach((item: TopicListBook) => this.setQuantityTopics(item));
    } else {
      this.createCategoryInformation(topic, 'videos');
      this.createCategoryInformation(topic, 'exercices');
      this.createCategoryInformation(topic, 'archives');
    }
  }

  createCategoryInformation(topic: Record<string, any>, context: string) {
    const listCategoryIds = Object.keys(topic[context]).filter((idCategory: string) => idCategory !== 'null');

    listCategoryIds.forEach((idCategory: string) => {
      if (!this.topicsQuantity[context][idCategory]) {
        this.topicsQuantity[context][idCategory] = { totalTime: 0, totalCount: 0 };
      }

      this.topicsQuantity[context][idCategory].totalTime += topic[context][idCategory].totalTime;
      this.topicsQuantity[context][idCategory].totalCount += topic[context][idCategory].totalCount;

      if (context === 'exercices') {
        if (!this.topicsQuantity.questions[idCategory]) {
          this.topicsQuantity.questions[idCategory] = { totalTime: 0, totalCount: 0 };
        }

        this.topicsQuantity.questions[idCategory].totalTime += topic.exercices[idCategory].totalTime;
        this.topicsQuantity.questions[idCategory].totalCount += topic.exercices[idCategory].totalQuestions;
      }
    });
  }

  findTeachers(teachers: Array<Teacher>) {
    if (teachers && teachers.length) {
      teachers.forEach((teacher: Teacher) => {
        this.listTeachers.push({
          id: teacher?.ID,
          show: true,
          name: teacher?.name,
          thumb: teacher?.thumb ? `https://explicae.com.br/app/${teacher?.thumb}` : DEFAULT_USERS,
          rating: 5.0,
        });
      });

      store.commit('setTeachers', this.listTeachers);
    }
  }

  setShowFilter() {
    this.showFilter = !this.showFilter;
  }

  redirectToBook(valueSelected: number) {
    const degreeIdSelected = this.findDegreeIdByValue(valueSelected);

    if (degreeIdSelected === undefined) return;

    const slugBookSelected = this.findSlugBookByDegreeID(degreeIdSelected);

    this.router.push({
      name: 'BooksView',
      params: {
        path: slugBookSelected,
      },
    });
  }

  findDegreeIdByValue(value: number) {
    return this.listSelectBookDegree.find((option: OptionDegreeSelect) => (option.value === value))?.degreeID;
  }

  findSlugBookByDegreeID(degreeID: number) {
    return this.relatedDisciplines.find((discipline: RelatedDisciplines) => (discipline.degreeID === degreeID))?.slug;
  }
}
