




























import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import ListIcons from '@/components/IconStatus/Icons/Icons.vue';

const DETAILS_DATA = [
  {
    title: 'Aulas',
    type: 'videos',
    typeIcon: 'video',
  },
  {
    title: 'Exercícios',
    type: 'exercices',
    typeIcon: 'exercise',
  },
  {
    title: 'Materiais',
    type: 'archives',
    typeIcon: 'material',
  },
  {
    title: 'Questões',
    type: 'questions',
    typeIcon: 'exercise',
  },
];
const SECONDS_OF_HOUR = 3600;
const SECONDS_OF_MINUTES = 60;

interface Infos {
  totalTime: number;
  totalCount: number;
}

@Component({
  components: {
    ListIcons,
  },
})
export default class ContentDetails extends Vue {
  @Prop() infoContent!: Record<string, any>;

  private DETAILS_DATA = DETAILS_DATA;

  private listInfo = {
    videos: {
      totalTime: 0,
      totalCount: 0,
    },
    exercices: {
      totalTime: 0,
      totalCount: 0,
    },
    archives: {
      totalTime: 0,
      totalCount: 0,
    },
    questions: {
      totalTime: 0,
      totalCount: 0,
    },
  };

  getQuantityAndCountContext(contextInfo: Record<string, any>) {
    const listCategoryIds = Object.keys(contextInfo);

    const totalInfo = listCategoryIds.reduce((acc: Infos, idCategory: string) => {
      const newValues = acc;

      newValues.totalTime += contextInfo[idCategory]?.totalTime;
      newValues.totalCount += contextInfo[idCategory]?.totalCount;

      return newValues;
    }, {
      totalTime: 0,
      totalCount: 0,
    });

    return totalInfo;
  }

  formatTime(seconds: number) {
    if (!seconds) return '';

    const hours = Math.floor(seconds / SECONDS_OF_HOUR);
    const minutes = Math.floor((seconds % SECONDS_OF_HOUR) / SECONDS_OF_MINUTES);
    return ` | ${hours}h ${minutes}m`;
  }

  formatCount(count: number) {
    return count < 10 ? `0${count}` : count;
  }

  hasContent(content: Record<string, any>) {
    return content.totalCount || content.totalTime;
  }

  @Watch('infoContent', {
    immediate: true,
    deep: true,
  })
  setQuantityAndCountContext() {
    if (this.infoContent.videos) this.listInfo.videos = this.getQuantityAndCountContext(this.infoContent.videos);

    if (this.infoContent.exercices) this.listInfo.exercices = this.getQuantityAndCountContext(this.infoContent.exercices);

    if (this.infoContent.archives) this.listInfo.archives = this.getQuantityAndCountContext(this.infoContent.archives);

    if (this.infoContent.questions) this.listInfo.questions = this.getQuantityAndCountContext(this.infoContent.questions);
  }
}
