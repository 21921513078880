



























import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

import Checkbox from '@/components/Input/Checkbox/index.vue';

@Component({
  components: {
    Checkbox,
  },
})
export default class FilterByContext extends Vue {
  @Prop({ required: true }) infoCategoryProp!: Record<string, any>;
  @Prop({ required: true }) context!: string;

  private infoCategory = this.infoCategoryProp;

  setCheckbox(name: string, checked: boolean, idCategory: string, checkValue: boolean) {
    this.infoCategory[idCategory] = {
      ...this.infoCategory[idCategory],
      checked: checkValue,
    };

    this.$emit('set-checkbox', this.infoCategory, this.context, Number(idCategory));
  }

}
