
























































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

import Accordion from '@/components/Accordion/index.vue';
import AdditionalContentData from '@/components/AdditionalContentData/index.vue';

import { TopicListBook } from '@/globalInterfaces/Book';

const TabAccordion = () => import('../TabSubject/index.vue');

@Component({
  name: 'SubjectTree',
  components: {
    Accordion,
    TabAccordion,
    AdditionalContentData,
  },
})
export default class SubjectAccordion extends Vue {
  @Prop() mainIndex!: number;
  @Prop() subject!: TopicListBook;
  @Prop({ default: false }) isTree!: boolean;
  @Prop({ default: 1 }) padding!: number;
  @Prop() bookSlug!: string;
  @Prop() idBook!: number;

  get hasSubject() {
    return Object.entries(this.subject).length
      && this.subject.topics
      && this.subject.topics.length;
  }

  verifyLesson(lesson: TopicListBook) {
    return (
      (lesson?.topics && lesson?.topics.length)
      || lesson?.videos
      || lesson?.archives
      || lesson?.exercices
    );
  }
}
