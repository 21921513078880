




















































import {
  Vue, Component, Watch, Mixins,
} from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import ListAllBooks from '@/components/ListAllBooks/index.vue';
import Footer from '@/components/Footer/Footer.vue';
import ListAccordionLoading from '@/components/ListAccordionLoading/index.vue';
// import CreditBox from '@/components/CreditBox/index.vue';

import BookContent from './components/BookContent/index.vue';
import LastClassViewed from './components/LastClassViewed/index.vue';
import LastClassViewedLoading from './components/LastClassViewed/LastClassViewedLoading.vue';
import Sidebar from './components/BookSidebar/index.vue';

import Search from '@/widgets/Util/Search/index.vue';
import LastListVideos from '@/widgets/Video/LastListVideos/index.vue';

// import { LIST_PLAN_ID } from '@/constant/PlansIDs';
// import { URL_BUTTON_CREDIT_BOOK } from '@/constant/FreePlanUrls';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import searchBook from '@/mixins/searchBook';
import SetSidebar from '@/mixins/SetSidebar';
import generalBooks from '@/mixins/generalBooks';

import { Book as IBook, TopicListBook } from '@/globalInterfaces/Book';
import { Teacher as TeacherVideo } from '@/globalInterfaces/Video';
import { Teacher } from '@/globalInterfaces/Teachers';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    ListAllBooks,
    Footer,
    BookContent,
    ListAccordionLoading,
    LastClassViewed,
    LastClassViewedLoading,
    Search,
    LastListVideos,
    // CreditBox,
  },
})
export default class Book extends Mixins(SetSidebar, generalBooks, searchBook) {
  private isMobile: boolean = this.$responsive.isMobile;
  private isLoadingBooks = true;

  private bookTopic: Array<TopicListBook> = [];
  private bookCurrent: IBook | null = null;
  private teachers: Array<Teacher> = [];

  // private URL_BUTTON_CREDIT_BOOK = URL_BUTTON_CREDIT_BOOK;

  async created() {
    this.onMobileChange();
    this.setSideBook();

    await this.loadData();

    this.setBreadCrumbs();
  }

  beforeDestroy() {
    this.$store.commit('setTeachers', []);
    this.$store.commit('clearFilterCategories');
  }

  get slugBook() {
    return this.$route.params.path;
  }

  // get plan() {
  //   return this.$store.getters.profile?.plan;
  // }

  // get isPlanFree() {
  //   return this.plan?.planID === LIST_PLAN_ID.FREE;
  // }

  get setClassCol() {
    // return this.isPlanFree ? 'col-xl-9 col-12 order-1 book__last-videos' : 'col-12';
    return 'col-12';
  }

  get isLoadingVideosWatched() {
    // return this.isPlanFree ? this.$store.getters.isLoadingVideosWatched : false;
    return false;
  }

  @Watch('slugBook')
  async loadData() {
    this.resetData();
    await this.getTopicsBook();
    await this.getBook();
    await this.getTeachers();
  }

  resetData() {
    this.bookTopic = [];
    this.bookCurrent = null;
    this.teachers = [];
  }

  async getTopicsBook() {
    try {
      const topics = await this.BookService.getTopicsBySlugBook(this.slugBook);

      if (topics) {
        this.bookTopic = topics;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o conteúdo do caderno.',
        status: 'error',
      });
      console.error(error);
    }
  }

  async getBook() {
    try {
      this.isLoadingBooks = true;
      const getAllBooks = await this.BookService.books(undefined, this.$store.getters.profile?.institution?.id);

      this.setBookCurrent(getAllBooks);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o caderno.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingBooks = false;
    }
  }

  setBookCurrent(allBooks: Array<IBook>) {
    const foundBook: IBook | undefined = allBooks.find(
      (book: IBook) => book.slug === this.slugBook,
    );

    if (!foundBook) return;

    this.bookCurrent = foundBook;
  }

  async getTeachers() {
    try {
      if (this.bookCurrent) {
        const { idBooks, slug } = this.bookCurrent;

        this.teachers = await this.BookService.getTeachers(idBooks, slug);
      }
    } catch (error) {
      console.error(error);
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os professores.',
        status: 'error',
      });
    }
  }

  @Watch('bookCurrent')
  async setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Cadernos ', to: '/cadernos' },
      { title: this.bookCurrent?.title || '' },
    ]);
  }

  concatTeachers(teachers: Array<TeacherVideo>) {
    return teachers.reduce((acc, teacher) => {
      if (acc) {
        return `${acc}, ${teacher.name}`;
      }
      return `${teacher.name}`;
    }, '');
  }

  @Watch('$responsive.isMobile')
  onMobileChange() {
    this.isMobile = this.$responsive.isMobile;
    this.$store.commit('setSidebar', !this.isMobile);
  }

  @Watch('bookTopic')
  @Watch('bookCurrent')
  @Watch('teachers')
  @Watch('slugBook')
  setSideBook() {
    const ComponentClass = Vue.extend(Sidebar);

    const instance = new ComponentClass({
      propsData: {
        cover: this.bookCurrent?.thumb,
        colorBook: this.bookCurrent?.color,
        bookTopic: this.bookTopic,
        teachers: this.teachers,
        title: this.bookCurrent?.title,
        degreeID: this.bookCurrent?.degreeID,
        slugBook: this.bookCurrent?.slug,
        router: this.$router,
        idBook: this.bookCurrent?.idBooks,
      },
    });

    this.setSidebar(instance);
  }

  setTrackAmplitude(local: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Book',
          local,
        },
      },
    });
  }
}
