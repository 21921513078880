




















import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

import {
  TopicListBook,
} from '@/globalInterfaces/Book';

interface ListInfo {
  info: {
    totalCount: number;
    totalTime: number;
  };
  title: string;
}

const SECONDS_OF_HOUR = 3600;
const SECONDS_OF_MINUTES = 60;

@Component({
  filters: {
    formatCount(count: number) {
      return count < 10 ? `0${count}` : count;
    },
  },
})
export default class AdditionalContentData extends Vue {
  @Prop() subject!: TopicListBook;

  private listInfo: Array<ListInfo> | [] = [];

  created() {
    this.listInfo = this.getListInfoContent();
  }

  getListInfoContent() {
    if (this.subject) {
      const INITIAL_DATA_INFOS = {
        videos: {
          totalTime: 0,
          totalCount: 0,
        },
        exercises: {
          totalTime: 0,
          totalCount: 0,
          totalQuestions: 0,
        },
        materials: {
          totalTime: 0,
          totalCount: 0,
          totalQuestions: 0,
        },
      };

      const quantityAndTimeContext = this.getQuantityAndTimeContext(this.subject, INITIAL_DATA_INFOS);

      return [
        {
          info: quantityAndTimeContext.videos,
          title: 'Aulas',
        },
        {
          info: quantityAndTimeContext.exercises,
          title: 'Exercícios',
        },
        {
          info: quantityAndTimeContext.materials,
          title: 'Materiais',
        },
      ];
    }

    return [];
  }

  getQuantityAndTimeContext(subject: TopicListBook, initialValue: Record<string, any>) {
    const quantityAndTime = initialValue;

    if (subject.topics.length) {
      subject.topics.forEach((topic: TopicListBook) => {
        const items = quantityAndTime;

        const newValues = this.getQuantityAndTimeContext(topic, items);

        quantityAndTime.videos.totalTime = newValues.videos.totalTime;
        quantityAndTime.videos.totalCount = newValues.videos.totalCount;

        quantityAndTime.exercises.totalTime = newValues.exercises.totalTime;
        quantityAndTime.exercises.totalCount = newValues.exercises.totalCount;
        quantityAndTime.exercises.totalQuestions = newValues.exercises.totalQuestions;

        quantityAndTime.materials.totalTime = newValues.materials.totalTime;
        quantityAndTime.materials.totalCount = newValues.materials.totalCount;
        quantityAndTime.materials.totalQuestions = newValues.materials.totalQuestions;
      });

    } else {
      quantityAndTime.videos.totalTime += this.getTotalContext(subject, 'videos', 'totalTime');
      quantityAndTime.videos.totalCount += this.getTotalContext(subject, 'videos', 'totalCount');

      quantityAndTime.exercises.totalTime += this.getTotalContext(subject, 'exercices', 'totalTime');
      quantityAndTime.exercises.totalCount += this.getTotalContext(subject, 'exercices', 'totalCount');
      quantityAndTime.exercises.totalQuestions += this.getTotalContext(subject, 'exercices', 'totalQuestions');

      quantityAndTime.materials.totalTime += this.getTotalContext(subject, 'archives', 'totalTime');
      quantityAndTime.materials.totalCount += this.getTotalContext(subject, 'archives', 'totalCount');
      quantityAndTime.materials.totalQuestions += this.getTotalContext(subject, 'archives', 'totalQuestions');

      return quantityAndTime;
    }

    return quantityAndTime;
  }

  getTotalContext(subject: Record<string, any>, context: string, type: string) {
    return Object.values(subject[context]).reduce((acc: number, category: any) => (acc + category[type]), 0);
  }

  hasContent(content: ListInfo) {
    return content.info.totalCount;
  }

  getTotalTime(timeInSeconds: number) {
    return timeInSeconds ? this.formatTime(timeInSeconds) : '';
  }

  formatTime(seconds: number) {
    const hours = Math.floor(seconds / SECONDS_OF_HOUR).toString().padStart(2, '0');
    const minutes = Math.floor((seconds % SECONDS_OF_HOUR) / SECONDS_OF_MINUTES).toString().padStart(2, '0');
    return ` | ${hours}h ${minutes}m`;
  }
}
