












































































































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

import store from '@/store';

import Close from '@/assets/icons/Close.vue';

import Accordion from '@/components/Accordion/index.vue';
import Checkbox from '@/components/Input/Checkbox/index.vue';

import FilterByContext from './components/FilterByContext/index.vue';

import Teachers from '@/widgets/Teachers/index.vue';

import { TeacherFilter } from '@/globalInterfaces/Teachers';

interface ContextData {
  id: number;
  items: Array<ListData>;
}

interface ListData {
  idCategory: number;
  title: string;
  quantity: number;
  time: string;
  checked: boolean;
}

const DATA_ACCORDION = [
  {
    id: 1,
    name: 'Aulas',
    idName: 'videos',
    checked: true,
  },
  {
    id: 2,
    name: 'Exercícios',
    idName: 'exercices',
    checked: true,
  },
  {
    id: 3,
    name: 'Materiais',
    idName: 'archives',
    checked: true,
  },
  {
    id: 4,
    name: 'Professores',
    idName: 'teachers',
    checked: true,
  },
];
const ID_VIDEOS = 1;
const ID_EXERCISES = 2;
const ID_MATERIALS = 3;
const ID_TEACHERS = 4;
const SECONDS_OF_HOUR = 3600;
const SECONDS_OF_MINUTES = 60;

@Component({
  components: {
    Close,
    Accordion,
    Checkbox,
    Teachers,
    FilterByContext,
  },
})
export default class ContentFilter extends Vue {
  @Prop() listTeachersProp!: Array<TeacherFilter>;
  @Prop() infoContent!: Record<string, any>;

  private listTeachers = this.listTeachersProp;
  private ID_VIDEOS = ID_VIDEOS;
  private ID_EXERCISES = ID_EXERCISES;
  private ID_MATERIALS = ID_MATERIALS;
  private ID_TEACHERS = ID_TEACHERS;

  private DATA_ACCORDION: Array<Record<string, any>> = DATA_ACCORDION;
  private dataFilters: Record<string, any> = {};
  private showTextFilter: Record<string, any> = {
    videos: true,
    exercices: true,
    archives: true,
    teachers: true,
  };

  created() {
    this.setDataFilterByContext();
  }

  get getTotalData() {
    const contextNames = Object.keys(this.dataFilters);
    const total = {
      totalCount: 0,
      totalTime: 0,
    };

    contextNames.forEach((context: string) => {
      total.totalCount += this.dataFilters[context].total.totalCount;
      total.totalTime += this.dataFilters[context].total.totalTime;
    });

    return {
      totalCount: total.totalCount,
      totalTime: total.totalTime,
    };
  }

  get getTextFilterTeachers() {
    const allIsChecked = this.listTeachers.every((teacher: TeacherFilter) => teacher.show);

    if (allIsChecked) return '<span>Todos</span>';

    const listTeachers = this.listTeachers.reduce((acc: string, teacher: TeacherFilter) => {
      let text = acc;

      if (teacher.show) {
        if (acc.length) text = `${text}    <span>${teacher.name}</span>`;
        else text = `<span>${teacher.name}</span>`;
      }

      return text;
    }, '');

    if (!listTeachers.length) return '<span>Nenhum</span>';

    return listTeachers;
  }

  get getTextFilterVideos() {
    const { categories } = this.dataFilters.videos;
    return this.setTextFilter(categories, 'Todas');
  }

  get getTextFilterExercises() {
    const { categories } = this.dataFilters.exercices;
    return this.setTextFilter(categories, 'Todos');
  }

  get getTextFilterMaterials() {
    const { categories } = this.dataFilters.archives;
    return this.setTextFilter(categories, 'Todos');
  }

  setTextFilter(categories: Record<string, any>, textForAll: string) {
    const idsCategory = Object.keys(categories);
    const allIsChecked = idsCategory.every((idCategory: string) => categories[idCategory].checked);

    if (allIsChecked) return `<span>${textForAll}</span>`;

    const listCategorys = idsCategory.reduce((acc: string, idCategory: string) => {
      let text = acc;

      if (categories[idCategory].checked) {
        if (acc.length) text = `${text}    <span>${categories[idCategory].title}</span>`;
        else text = `<span>${categories[idCategory].title}</span>`;
      }

      return text;
    }, '');

    if (!listCategorys.length) return '<span>Nenhum</span>';

    return listCategorys;
  }

  closeFilter() {
    this.$emit('set-show-filter');
  }

  setCheckbox(name: string, checked: boolean, id: number, checkValue: boolean) {
    let nameContext = '';

    this.DATA_ACCORDION = this.DATA_ACCORDION.map((item: Record<string, any>) => {
      if (item.id === id) {
        nameContext = item.idName;
        return { ...item, checked: checkValue };
      }

      return item;
    });

    if (id !== ID_TEACHERS) this.setAllCheckboxContext(checkValue, nameContext);
    else this.setAllCheckboxTeachers(checkValue);
  }

  setAllCheckboxContext(checked: boolean, nameContext: string) {
    const idsCategoryContext = Object.keys(this.dataFilters[nameContext]?.categories);

    idsCategoryContext.forEach((idCategory: string) => {
      this.dataFilters[nameContext].categories[idCategory] = {
        ...this.dataFilters[nameContext].categories[idCategory],
        checked,
      };
    });

    this.saveMaterialCategoryFilteredInStore(nameContext, null, checked);
  }

  setAllCheckboxTeachers(checked: boolean) {
    this.listTeachers = this.listTeachers.map((teacher: TeacherFilter) => ({ ...teacher, show: checked }));
  }

  resetCheckboxDataAccordion() {
    this.DATA_ACCORDION.forEach((item: Record<string, any>) => this.setCheckbox('', true, item.id, true));
  }

  updateCheckedListData(newListCategorys: ContextData, context: string, idCategory: number) {
    this.dataFilters[context].categories = newListCategorys;

    this.saveMaterialCategoryFilteredInStore(context, idCategory);

    this.verifyCheckedsOfContext(this.dataFilters[context]?.categories, context);
  }

  verifyCheckedsOfContext(filtersContext: Record<string, any>, context: string) {
    const idsCategoryContext = Object.keys(filtersContext);

    const allCategoryUnchecked = idsCategoryContext.every((key: string) => !filtersContext[key].checked);

    if (allCategoryUnchecked) {
      this.setUncheckedContext(context, false);
      return;
    }

    const someCategoryChecked = idsCategoryContext.some((key: string) => filtersContext[key].checked);

    if (someCategoryChecked) this.setUncheckedContext(context, true);
  }

  setUncheckedContext(context: string, checked: boolean) {
    this.DATA_ACCORDION = this.DATA_ACCORDION.map((item: Record<string, any>) => {
      if (item.idName === context) {
        return { ...item, checked };
      }

      return item;
    });
  }

  saveMaterialCategoryFilteredInStore(context: string, idCategory: number | null, checkedAll?: boolean) {
    store.commit('setCategoryFilterByContext', {
      context,
      idCategory,
      checkedAll,
    });
  }

  setDataFilterByContext() {
    const filtersByContext: Record<string, any> = {
      videos: {},
      exercices: {},
      archives: {},
    };

    const idsCategoryVideos = Object.keys(this.infoContent.videos);
    const idsCategoryExercises = Object.keys(this.infoContent.exercices);
    const idsCategoryMaterials = Object.keys(this.infoContent.archives);

    filtersByContext.videos = this.createObjectsFromCategoriesAndTotal(idsCategoryVideos, 'videos');
    filtersByContext.exercices = this.createObjectsFromCategoriesAndTotal(idsCategoryExercises, 'exercices');
    filtersByContext.archives = this.createObjectsFromCategoriesAndTotal(idsCategoryMaterials, 'archives');

    this.dataFilters = filtersByContext;
  }

  createObjectsFromCategoriesAndTotal(idsCategory: Array<string>, context: string) {
    const categoryObjectForStore: Record<string, any> = [];
    const categoryObject: Record<string, any> = {};
    const totalObjectOfCategories: Record<string, any> = {
      totalCount: 0,
      totalTime: 0,
    };

    idsCategory.forEach((idCategory: string) => {
      categoryObject[idCategory] = {
        idCategory: Number(idCategory),
        title: this.getTitleCategory(Number(idCategory)),
        quantity: this.infoContent[context][idCategory].totalCount,
        time: this.formatTime(this.infoContent[context][idCategory].totalTime),
        checked: true,
      };

      categoryObjectForStore.push(
        {
          idCategory: Number(idCategory),
          checked: true,
        },
      );

      totalObjectOfCategories.totalCount += this.infoContent[context][idCategory].totalCount;
      totalObjectOfCategories.totalTime += this.infoContent[context][idCategory].totalTime;
    });

    this.saveCategoryListInStore(categoryObjectForStore, context);

    return {
      total: totalObjectOfCategories,
      categories: categoryObject,
    };
  }

  getTitleCategory(idCategory: number) {
    switch (idCategory) {
      case 1:
        return 'Teoria';
      case 2:
        return 'Teoria + Orientadas';
      case 3:
        return 'Orientadas';
      case 4:
        return 'Revisão';
      case 5:
        return 'ENEM';
      case 6:
        return 'Vestibulares';
      case 7:
        return 'Fuvest';
      case 8:
        return 'Medicina';
      case 9:
        return 'Ementa';
      case 10:
        return 'Prova';
      case 11:
        return 'Gabarito';
      default:
        return '';
    }
  }

  formatTime(seconds: number) {
    if (!seconds) return '';

    const hours = Math.floor(seconds / SECONDS_OF_HOUR);
    const minutes = Math.floor((seconds % SECONDS_OF_HOUR) / SECONDS_OF_MINUTES);
    return ` | ${hours}h ${minutes}m`;
  }

  saveCategoryListInStore(categories: Record<string, any>, context: string) {
    store.commit('saveFilterCategories', {
      context,
      categories,
    });
  }

  setShowTextFilter(show: boolean, nameCategory: string) {
    this.showTextFilter[nameCategory] = show;
  }

  hasContent(item: Record<string, any>) {
    if (item.id === ID_TEACHERS) return this.listTeachers.length;

    return this.dataFilters[item.idName].total.totalCount;
  }
}
