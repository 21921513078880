





















import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

@Component({})
export default class LastClassViewed extends Vue {
  @Prop() lastClass!: Record<string, any>;

  handleClickStartWatching() {
    this.$emit('send-amplitude', 'Começar a assistir');

    this.$router.push({
      name: 'videos',
      params: {
        context: 'caderno',
        path: this.$route.params.path,
        topicID: this.lastClass.idTopic,
        fileID: this.lastClass.file.idVideo,
        slugFile: this.lastClass.file.slug,
      },
    });
  }
}
